.input-with-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
}

.input-with-button-container input {
    flex-grow: 1;
    line-height: 1.6rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #E0E2E9;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.07);
    font-weight: 400;
    font-size: 13px;
    outline: 0;
    height: 2.5rem;
}

.input-with-button-container input:focus-visible {
    border: 1px solid #a6b3ff;
    box-shadow: 0px 0px 0px 2px #f0f1fb, inset 0px 2px 2px rgba(0, 0, 0, 0.07);
    outline : none;
}

.input-with-button-container button {
    font-size: 13px;
    border-radius: 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
    width: 5rem;
    background: #5567D5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    color: #fff;
    border: none;
    height: 2.5rem;
}

.input-with-button-container button:hover {
    background: #4D5EC0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
}