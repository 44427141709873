.new-task-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.new-task-group-container span {
    font-size: 3rem;
    background: white;
    border-radius: 1.5rem;
    width: 3rem;
    height: 3rem;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 1px 1px #D2D5DD;
    display: flex;
    align-content: center;
    justify-content: center;
}

.new-task-group-container img {
    width: 1.5rem;
}

.new-task-group-container span:hover {
    box-shadow: 0 0 9px -4px;
}

.close-character {
    margin-right: 0.5rem;
}