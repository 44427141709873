.banner-container {
    background: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border-radius: 1rem;
    border: 1px solid red;
    box-shadow: 0 0 11px -6px;
    padding: 1rem;
}

.banner-title {}

.banner-text {
    padding: 1rem;
}