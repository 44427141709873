.new-todo {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
}

.new-todo input {
    border: 1px solid #e0e2e9;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.07);
    border-radius: 0.3125rem;
    flex-grow: 1;
    line-height: 1.5rem;
    padding: 0.375rem 0.75rem;
    height: 2.5rem;
    font-size: 15px;
}

.new-todo input:focus-visible {
    border: 1px solid #a6b3ff;
    box-shadow: 0px 0px 0px 2px #f0f1fb, inset 0px 2px 2px rgba(0, 0, 0, 0.07);
    outline : none;
}

.new-todo button {
    background: #5567d5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    padding: 4px 16px;
    text-align: center;
    font-weight: 300;
    width: 4.8rem;
    height: 2.5rem;
    color: #fff;
    line-height: 1.5rem;
    font-size: 0.8125rem;
    border-radius: 0.3125rem;
    cursor: pointer;
    border: none;
    text-transform: capitalize;
}

.new-todo button:hover {
    background-color: #a6b3ff;
}

.new-todo button:disabled {
    background: #5567D5;
    opacity: 0.2;
    border-radius: 5px;
    color: #fff;
}