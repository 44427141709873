.landing-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.login-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: var(--main-radius);
    box-shadow: 0px 1px 1px #D2D5DD;
    width: 27rem;
    height: 22rem;
    background-color: white;
}
.login-card > img {
    margin-top: 3.6rem;
}

.login-card h3 {
    margin: 3.8rem 0 0.18rem ;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #40404b;
}

.login-tagline {
    margin: 0 0 1.62rem;
    font-weight: 400;
    color: #8d91a3;
}

.landing-wrapper .card {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-wrapper .divider {
    border-right: 1px solid black;
}
