.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.header-right {
    display: flex;
    column-gap: 0.5rem;
}

.header-left {
    display: flex;
    flex-grow: 1;
    column-gap: 1rem;
    align-items: center;
}

.choreo-logo-container {
    width: 3rem;
    border-right: 1px solid;
}

.header-left h4 {
    margin: 0;
    font-style: normal;
    font-weight: 600;
}
