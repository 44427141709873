.task-list-ul {
    list-style: none;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 0;
}

.todo-list-container {
   height: 55vh;
}

.no-tasks {
    width: auto;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    color: #696b76;
    font-weight: 200;
    font-size: 15px;
}