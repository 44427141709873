.login-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.user-avatar {
    width: 2.5rem;
    margin-right: 1rem;
    border-radius: 1.25rem;
}

.gray-logo {
    filter: grayscale(1);
}

.avatar-button {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    border-radius: 0.5rem;
    border: none;
    background: white;
    padding: 0.5rem;
    cursor: pointer;
}

.title-class {
    font-family: inherit;
    font-size: 15px;
    line-height: 20px;
    color: #222228;
    margin-right: 1rem;
}

.login-panel .avatar-dropdown {
    margin-left: 0rem;
    margin-top: 3.8rem;
}

.dropdown-content.avatar-dropdown a:hover {
    background-color: #e1e1e1;
}
