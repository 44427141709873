.search-container {
    width: 30vw
}

.search-container input {
    width: 100%;
    height: 40px;
    padding: 0.25rem 0.75rem;
    border-radius: 0.3125rem;
    background: #fff;
    border: 1px solid #e0e2e9;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    font-size: 0.9375rem;
    margin-left: 0.75rem;
}

.search-container input:focus  {
    background: #fff;
    border: 1px solid #A6B3FF;
    box-shadow: 0px 0px 0px 2px #F0F1FB, inset 0px 2px 2px rgba(0, 0, 0, 0.07);
    border-radius: 0.3125rem;
    outline : none;
}
