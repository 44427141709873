.task-group-container {
    background: #ffffff;
    box-shadow: 0px 1px 1px #d2d5dd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0 1rem;
    border: 0.1rem solid #00000021;
    border-radius: 0.625rem;
    width: 25rem;
    position: relative;
}

.group-deleting-overlay {
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #fe523c;
    color: #fe523c;
    flex-direction: column;
    row-gap: 1rem;
}

.group-deleting-overlay button {
    padding: 0.325rem 1rem;
    background: white;
    border: 1px solid #5567d5;
    border-radius: 0.3125rem;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 15%);
}

.new-todo-layout {
    width: 100%;
    padding: 1.5rem 1rem;
    background: #fff;
}

.todo-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 55vh;
    row-gap: 1rem;
    overflow-y: scroll;
    padding: 0 1rem;
    margin-bottom: 2.5rem;
}

.todo-list::-webkit-scrollbar {
    width: 0.35em;
}

.todo-list::-webkit-scrollbar-track {
    background-color: #fff;
}

.todo-list::-webkit-scrollbar-thumb {
    background-color: #8d91a3;
    border-radius: 5px;
}

.show-only-active {
    width: 1.2rem;
    height: 1.2rem;
}

.active-selector {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    flex-grow: 1;
    justify-content: center;
}

.active-selector label {
    cursor: pointer;
}

.todo-actions {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    font-size: 0.8125rem;
    line-height: 1.25rem;
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    background-color: #fff;
    padding-top: 0.5rem;
}

.task-group-name {
    margin: 0;
}

.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    height: 3.5rem;
    padding: 0 1rem;
    border-bottom: 1px solid #d8d8d8;
}

.group-header-content {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    max-width: 79%;
}

.group-header-content h5 {
    font-size: 16px;
    line-height: 24px;
    color: #222228;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 500;
}
