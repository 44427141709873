.todo-item-overlay {
    position: absolute;
    width: 100%;
    background: #fff;
    height: 100%;
    margin: 0rem -0.625rem;
    border-radius: 0.3125rem;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #5567d5;
    border: 1px dashed #5567d5;
    font-size: 14px;
    font-weight: 400;
}

.todo-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.todo-item-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.2rem;
}

.todo-item-text {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    font-size: 0.8125rem;
    line-height: 1rem;
}

.todo-item-li {
    cursor: move;
    list-style: none;
    padding: 0 10px;
    font-size: 1.2rem;
    width: 100%;
    border-radius: 0.3125rem;
    transition: all 0.1s ease-in-out;
    backdrop-filter: blur(2px);
    width: 100%;
    transform: translateZ(0);
}

.todo-item-li:hover {
    transform: scale(1.01, 1.01);
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
}

.drawer-opened {
    z-index: 1;
    position: relative;
}
