.task-list-container {
    display: flex;
    width: 95vw;
    height: 80vh;
    overflow-x: scroll;
    column-gap: 1.25rem;
    padding-bottom: 1rem;
    padding-top: 1.5rem;
}

.task-list-container::-webkit-scrollbar {
    width: 0.35em;
    height: 0em;
}
   
.task-list-containert::-webkit-scrollbar-track {
    background-color: #fff;
}
   
.task-list-container::-webkit-scrollbar-thumb {
    background-color: #8D91A3;
    border-radius: 5px;
}

.right-shadow {
    border-right: 1px solid #dfdfdf3d;
}

.left-shadow {
    border-right: 1px solid #dfdfdf3d;
}

.left-right-shadow {
    border-right: 1px solid #dfdfdf3d;
}

.listing-notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 100%;
    width: 50%;
}

.scroller-button {
    position: absolute;
    top: 40%;
    z-index: 1;
}

.scroller-button button {
    background: #fff;
    border-radius: 1.5rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    border: none;
    border: 1px solid #e2e2e24b;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 1px #D2D5DD;
}
.scroller-button button img {
    width: 5rem;
}

.scroller-button-left {
    left: 0rem;
}

/* .scroller-button-left button::before {
    margin-top: -1.9rem;
    margin-left: -0.6rem;
    position: absolute;
} */

.scroller-button-right {
    right: 0.5rem;
}

/* .scroller-button-right button::before {
    margin-top: -1.8rem;
    margin-left: -0.3rem;
    position: absolute;
} */

.filter-and-search {
    display: flex;
    column-gap: 1rem;
    margin-left: 0.5rem; /* Counter act empty div */
}

.task-container {
    margin-left: 2rem;
}
