.filter-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    width: 20vw;
}

.filter-container select {
    padding: 0.625rem 0.625rem 0.625rem 0.75rem;
    width: 9rem;
    height: 2.5rem;
    background: #ffffff;
    color: #40404B;
    border: 1px solid #e0e2e9;
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.08),
        inset 0px -3px 9px rgba(0, 0, 0, 0.04);
    border-radius: 0.3125rem;
    outline: 0;
    font-size: 13px;
    margin-right: 1rem;
}
.drop-down {
    background-color: aqua;
}

.drop-down::after {
    margin-right: 5rem;
}
