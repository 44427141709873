.base-grid {
    display: grid;
    grid-gap: 1.5rem;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 1fr 2rem;
}

.grid-footer {
    color: #646464;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1rem;
    background: white;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 8%), inset 0px -1px 0px #e1e2e7;
}

.top-bar {
    height: 4rem;
    padding: 0 1rem;
    /* padding: 1rem 2rem 1rem 1.6rem; */
    display: flex;
    align-items: center;
    gap: 140px;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 8%), inset 0px -1px 0px #e1e2e7;
}

.task-board {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer-choreo-link {
    text-decoration: inherit;
    margin-left: 0.3rem;
    transition: color 0.3s linear;
}

.footer-choreo-link:hover {
    color: #5567d5;
}
